import React, {useContext, useState} from 'react';
import favIcon from '../../assets/icons/fav-on.svg'
import favOff from '../../assets/icons/fav-off.svg'
import tvIcon from '../../assets/icons/tv-icon.png'
import dayjs from "dayjs";
import StatsRow from "./StatsRow";
import StatsRowNoInfo from "./StatsRowNoInfo";
import {AppContext} from "../../context/AppContext";
import OddsStatsRow from "./odds/OddsStatsRow";
import {saveMatchToLocalStorage} from "../local-storage/ManageLocalStorage";

function Matches(props) {
    const {addAndRemoveFavOddsResults, isFavOddsResults, lang, timeZone, i18String} = useContext(AppContext);
    const {index, favMatches, matches, statsType, league, selectedTypeForSelect} = props;

    const startTime = (value) => {
        return dayjs(value).tz(timeZone).format("HH:mm");
    }

    if (favMatches?.length === 0) {
        return (
            <div className='no-matches-that-match-filter'>
                {
                    lang === 'en' ?
                        <span>There are no matches among your favorite matches that match the filter you want!</span>
                        :
                        <span>Favori maçlar arasında istediğiniz filtreye uygun maç yok!</span>
                }
            </div>
        )
    }

    return (
        <div>
            {
                matches.map((match) => {

                    const oddsData = [];
                    if (match?.ft?.odds) {
                        match.ft.odds.forEach(odd => {
                            oddsData.push(odd);
                        });
                    } else {
                        // FT yoksa 3 tane boş değer ekle
                        for (let i = 0; i < 3; i++) {
                            oddsData.push({ name: `FT Empty ${i + 1}`, value: null });
                        }
                    }

                    if (match?.uo?.odds) {
                        match.uo.odds.forEach(odd => {
                            oddsData.push(odd);
                        });
                    } else {
                        // UO yoksa 2 tane boş değer ekle
                        for (let i = 0; i < 2; i++) {
                            oddsData.push({ name: `UO Empty ${i + 1}`, value: null });
                        }
                    }

                    return (
                        <div key={match.id}>
                            {/*WEB*/}
                            <div className="matches-container match-team-names">
                                <table className="fixtures-table-section">
                                    <tbody>
                                    <tr className="fixture-table-row">
                                        <td className="odds-rslt-match-info-td">
                                            <div className={statsType === 'avgStats' ? 'odds-rslt-match-info-container-for-avg' : 'odds-rslt-match-info-container'}>
                                                <div>
                                                    {
                                                        isFavOddsResults(match.id) ?
                                                            <img
                                                                onClick={() => addAndRemoveFavOddsResults(league, match)}
                                                                className="fav-icon" src={favIcon} alt="fav"/> :
                                                            <img
                                                                onClick={() => addAndRemoveFavOddsResults(league, match)}
                                                                className="fav-icon" src={favOff} alt="fav"/>
                                                    }
                                                </div>

                                                <div>
                                                    {(match.status === 'Abandoned' || match.status === 'Suspended') && (
                                                        <div>
                                                            {lang === 'en' ? <span className='abnd-match'>Abnd.</span> :
                                                                <span className='abnd-match'>Yrdk</span>}
                                                        </div>
                                                    )}
                                                    {match.status === 'Fixture' &&
                                                        <span>{startTime(match.dateTimeUtc)}</span>}
                                                    {match.status === 'Played' && <span>MS</span>}
                                                    {match.status === 'Playing' && match.st !== 'HT' &&
                                                        <span className="live-match-min">{match.min}'</span>}
                                                    {match.st === 'HT' && <span className="live-match-min">HT</span>}
                                                    {match.status === 'Postponed' &&
                                                        <div>
                                                            {
                                                                lang === 'en' ?
                                                                    <span className="live-match-min">Post.</span>
                                                                    :
                                                                    <span className="live-match-min">Ert.</span>
                                                            }
                                                        </div>
                                                    }
                                                    {match.status === 'Cancelled' &&
                                                        <div>
                                                            {
                                                                lang === 'en' ?
                                                                    <span className="live-match-min">Canc.</span>
                                                                    :
                                                                    <span className="live-match-min">İptl.</span>
                                                            }
                                                        </div>
                                                    }
                                                </div>

                                                <div className="card-container">
                                                    {match?.tv &&
                                                        <span title={match.tv}>
                                                            <img src={tvIcon} alt="Tv" style={{ width: '13px', height: '13px' }} />
                                                        </span>
                                                    }
                                                </div>

                                                <div className="card-container">
                                                    {match.hred > 0 &&
                                                        <div className="match-card red-card">{match.hred}</div>}
                                                </div>

                                                <div className="card-container">
                                                    {match.hyellow > 0 &&
                                                        <div className="match-card yellow-card">{match.hyellow}</div>}
                                                </div>

                                                <a target={"_blank"} href={`/team-detail/${match.homeId}`} className="match-team-name">
                                                    {match.home}
                                                </a>
                                                <div
                                                    className="score-container"
                                                    onClick={() => saveMatchToLocalStorage(`teams-state:${match.id}`, {
                                                        matchId: match.id,
                                                        home: match.home,
                                                        homeId: match.homeId,
                                                        away: match.away,
                                                        awayId: match.awayId,
                                                        time: match.dateTimeUtc,
                                                        seasonId: match.seasonId
                                                    })}
                                                >
                                                    {match.status === 'Playing' &&
                                                        <span><span
                                                            className="live-match-score">{match.hscore} -</span>  <span
                                                            className="live-match-score">{match.ascore}</span></span>}
                                                    {match.status === 'Played' &&
                                                        <span
                                                            style={{
                                                                color: 'var(--Black01)',
                                                                fontWeight: '600'
                                                            }}>{match.hscore} - {match.ascore}</span>}
                                                    {match.status === 'Fixture' ||
                                                    match.status === 'Abandoned' ||
                                                    match.status === 'Postponed' ||
                                                    match.status === 'Suspended' ||
                                                    match.status === 'None' ||
                                                    match.status === 'Cancelled'
                                                        ?
                                                        <span className='versus-text'>-</span>
                                                            :
                                                        null
                                                    }
                                                    {(match.status === 'Abandoned' || match.status === 'Suspended') &&
                                                        <span><span
                                                            className="live-match-score">{match.hscore} -</span>  <span
                                                            className="live-match-score">{match.ascore}</span></span>}
                                                </div>
                                                <a target={"_blank"} href={`/team-detail/${match.awayId}`} className="match-team-name away-team-name">
                                                    {match.away}
                                                </a>

                                                <div className="card-container">
                                                    {match.ayellow > 0 &&
                                                        <div className="match-card yellow-card">{match.ayellow}</div>}
                                                </div>

                                                <div className="card-container">
                                                    {match.ared > 0 &&
                                                        <div className="match-card red-card">{match.ared}</div>}
                                                </div>

                                                <div>
                                                    {
                                                        match?.h2 === true &&
                                                        <span
                                                            className='live-scores-1-half'>{match.hp1}-{match.ap1}</span>
                                                    }
                                                </div>

                                                <div className="card-container">
                                                    {match.hcorner + match.acorner > 0 &&
                                                        <div className="live-scores-1-half">{match.hcorner}-{match.acorner}</div>}
                                                </div>

                                                <div></div>
                                                <div></div>
                                            </div>
                                        </td>
                                        {/*ONLY REFEREE STATS*/}
                                        {
                                            statsType === 'refereeStats' &&
                                            <td className='stats-matches-referee-name-td-container'>
                                                { match?.ref &&
                                                    <div className='stats-matches-referee-name-container'>
                                                        <a className='referee-name-match-detail' target={"_blank"}
                                                           href={`/referee/${match?.ref?.id}/season/${match?.seasonId}`}>
                                                            {match?.ref?.name}
                                                        </a>
                                                    </div>
                                                }
                                            </td>
                                        }

                                        {
                                            statsType === 'oddsStats' ? (
                                                match?.ft?.odds || match?.uo?.odds ? (
                                                    oddsData.map(odd => (
                                                        <OddsStatsRow key={odd.name} value={odd.value} statsType={statsType} />
                                                    ))
                                                ) : (
                                                    <StatsRowNoInfo borderStyle="no-odds-info-stats" />
                                                )
                                            ) : match[statsType] ? (
                                                match[statsType].map((stat, index) => (
                                                    <StatsRow
                                                        index={index}
                                                        key={stat.name}
                                                        /* borderStyle={statStyle(stat)} */
                                                        stat={stat}
                                                        statsType={statsType}
                                                    />
                                                ))
                                            ) : (
                                                <StatsRowNoInfo
                                                    borderStyle={statsType === 'avgStats' ? 'no-info-stats-for-avg' : 'no-info-stats'}
                                                />
                                            )
                                        }

                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/*MOBILE*/}
                            <div className="fixtures-section-mobile match-team-names">
                                <table className="fixtures-table-section">
                                    <tbody>
                                    <tr className="fixture-table-row">
                                        <td className="odds-rslt-match-info-td">
                                            <div className="odds-rslt-match-info-container">

                                                <div>
                                                    {match.status === 'Played' && <span>MS</span>}
                                                    {match.status === 'Postponed' && <span className='postponed-status'>{i18String('Post.')}</span>}
                                                    {match.status === 'Playing' && match.st !== 'HT' &&
                                                        <span className="live-match-min">{match.min}'</span>}
                                                    {match?.st === 'HT' && <span className="live-match-min">HT</span>}
                                                </div>

                                                <div className="card-container">
                                                    {match.hred > 0 &&
                                                        <div className="match-card red-card">{match.hred}</div>}
                                                </div>

                                                <a target={"_blank"} href={`/team-detail/${match.homeId}`} className="match-team-name">
                                                    {match.home}
                                                </a>
                                                <div className="score-container-bet-mode"
                                                     onClick={() => saveMatchToLocalStorage(`teams-state:${match.id}`, {
                                                         matchId: match.id,
                                                         home: match.home,
                                                         homeId: match.homeId,
                                                         away: match.away,
                                                         awayId: match.awayId,
                                                         time: match.dateTimeUtc,
                                                         seasonId: match.seasonId
                                                     })}
                                                >
                                                    {match.status === 'Playing' &&
                                                        <span><span
                                                            style={{color: 'var(--H3Red01)', fontWeight: '600'}}>{match.hscore} -</span>  <span
                                                            style={{color: 'var(--H3Red01)', fontWeight: '600'}}>{match.ascore}</span></span>}
                                                    {match.status === 'Played' &&
                                                        <span
                                                            style={{color: 'black', fontWeight: '600'}}>{match.hscore} - {match.ascore}</span>}
                                                    {match.status === 'Fixture' &&
                                                        <span className='bet-mode-start-time-for-mobile'>
                                                            {match.dateTimeUtc ? startTime(match.dateTimeUtc) : '-'}
                                                        </span>
                                                    }
                                                    {match.status === 'Postponed' &&
                                                        <span className='bet-mode-start-time-for-mobile'>
                                                            {match.dateTimeUtc ? startTime(match.dateTimeUtc) : '-'}
                                                        </span>
                                                    }
                                                </div>
                                                <a target={"_blank"} href={`/team-detail/${match.awayId}`} className="match-team-name away-team-name">
                                                    {match.away}
                                                </a>

                                                <div className="card-container">
                                                    {match.ared > 0 &&
                                                        <div className="match-card red-card">{match.ared}</div>}
                                                </div>

                                                <div>
                                                    {
                                                        isFavOddsResults(match.id) ?
                                                            <img
                                                                onClick={() => addAndRemoveFavOddsResults(league, match)}
                                                                className="fav-icon" src={favIcon} alt="fav"/> :
                                                            <img
                                                                onClick={() => addAndRemoveFavOddsResults(league, match)}
                                                                className="fav-icon" src={favOff} alt="fav"/>
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style={{display: 'flex', background: '#f8f8f8', padding: '5px'}}>
                                        {
                                            statsType === 'oddsStats' && (
                                                    match?.ft?.odds || match?.uo?.odds ? (
                                                        oddsData.map(odd => (
                                                            <OddsStatsRow key={odd.name} value={odd.value} oddsName={odd.name} statsType={statsType} />
                                                        ))
                                                    ) : (
                                                        <StatsRowNoInfo borderStyle="no-odds-info-stats" />
                                                    )
                                                )
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default Matches;
