import React, {useContext, useEffect, useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import fixturesIcon from '../../assets/icons/matches.png'
import fixturesSelectedIcon from '../../assets/icons/matches-selected.png'
import betMode from '../../assets/icons/betMode.png'
import betModeSelected from '../../assets/icons/betModeSelected.png'
import bestTeams from '../../assets/icons/best-teams.png'
import bestTeamsSelected from '../../assets/icons/best-teams-selected.png'
import statsIcon from '../../assets/icons/statsIcon.png'
import statsSelectedIcon from '../../assets/icons/statsSelectedIcon.png'
import trendMatchesIcon from '../../assets/icons/trendMatches.png'
import matchesIcon from '../../assets/icons/matches.png'
import selectedMatchesIcon from '../../assets/icons/matches-selected.png'
import trendMatchesSelected from '../../assets/icons/trendMatchesSelected.png'
import {AppContext} from "../../context/AppContext";

function TopMenus(props) {
    const {lang, i18String} = useContext(AppContext);

    const [menuWeb, setMenuWeb] = useState([]);
    const [menuMobile, setMenuMobile] = useState([]);

    useEffect(() => {
        // i18String değeri değiştiğinde menuWeb'i güncelle
        setMenuWeb([
            { text: i18String('Live Scores'), to: '/' },
            { text: i18String('Bet Mode'), to: '/scoreradar' },
            { text: i18String('Best Teams'), to: '/best-teams' },
            { text: i18String('Best Matches'), to: '/trend-matches' },
            { text: i18String('Leagues'), to: '/league' },
            { text: i18String('TV Guide'), to: '/tv' },
            { text: i18String('Results'), to: '/results' },
        ]);

        setMenuMobile([
            /*{text: 'Matches', to: '/', src: fixturesIcon, selectedSrc: fixturesSelectedIcon, toggle: 0},*/
            {text: i18String('Live Scores'), to: '/', src: statsIcon, selectedSrc: statsSelectedIcon, toggle: 1},
            /*{text: i18String('Favorites'), to: '/fav-matches', src: trendMatchesIcon, selectedSrc: trendMatchesSelected, toggle: 2},*/
            {text: i18String('Bet Mode'), to: '/scoreradar', src: betMode, selectedSrc: betModeSelected, toggle: 3},
            {text: i18String('Best Teams'), to: '/best-teams', src: bestTeams, selectedSrc: bestTeamsSelected, toggle: 4},
            {
                text: i18String('Best Matches'),
                to: '/trend-matches',
                src: matchesIcon,
                selectedSrc: selectedMatchesIcon,
                toggle: 5
            },
        ])
    }, [i18String]);

    const setActiveMenuStyle = (isActive) => {
        if (isActive) {
            return {
                color: 'var(--H3Dark1)',
                borderBottom: '3px solid var(--H3Dark1)',
                padding: '0 16px 7px 16px',
                fontWeight: '600',
                fontSize: '14px'
            }
        } else {
            return {
                color: 'var(--H3MidGrey1)',
                borderBottom: 'none',
                paddingBottom: '0',
                padding: '0 16px',
                fontWeight: '600',
                fontSize: '14px'
            }
        }
    }

    const setActiveMenuStyleMobile = (isActive) => {
        if (isActive) {
            return {
                minHeight: '2.3rem',
                display: 'grid',
                placeItems: 'center',
                fontSize: '12px',
                transitionDelay: '.1s',
                color: '#ff0046',
                transitionDuration: '.2s',
            }
        } else {
            return {
                color: '#757d86',
                borderBottom: 'none',
                paddingBottom: '0',
                minHeight: '2.3rem',
                placeItems: 'center',
                fontSize: '12px',
                transitionDelay: '.1s',
                transitionDuration: '.2s',
            }
        }
    }

    return (
        <div className="top-menu-container">
            <div className="fv-menu-items-container">
                {
                    menuWeb.map((menu) => {
                        return (
                            /*<a className="top-menu-a" key={menu.to} href={menu.to}>{menu.text}</a>*/
                            <NavLink key={menu.to} style={({isActive}) => (setActiveMenuStyle(isActive))}
                                     className="fv-menu-item" to={menu.to}>{menu.text}</NavLink>
                        )
                    })
                }
            </div>


            {/*<GoogleAds currentPath={currentRoutes.pathname}></GoogleAds>*/}

            <div className="menu-items-container-mobile">
                {
                    menuMobile.map((menu) => {
                        return (
                            <NavLink key={menu.to}
                                     style={({isActive}) => (setActiveMenuStyleMobile(isActive))}
                                     children={({isActive}) => {
                                         const imgSrc = isActive ? menu.selectedSrc : menu.src;
                                         return(
                                             <>
                                                 <img className={'menu-icons'} src={imgSrc} alt={menu.text}/>
                                                 <div className='mobile-menu-title'>{menu.text}</div>
                                             </>
                                         )
                                     }}
                                     className="menu-item" to={menu.to}
                            >
                            </NavLink>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default TopMenus;
