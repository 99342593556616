import React, {useContext, useEffect, useState} from 'react';
import livePng from '../../assets/icons/live-icon.png'
import dayjs from "dayjs";
import './OddsResults.css'
import '../../App.css'
import {fetchFavoritesMatches, fetchFixtures, fetchLiteMatches} from "../../api";
import LeagueAndMatchesTable from "./LeagueAndMatchesTable";
import {AppContext} from "../../context/AppContext";
import DocumentMeta from "react-document-meta";
import searchIcon from '../../assets/icons/searchIconWhite.png'
import {homepageMeta} from "../../SEO/SeoMetaTags";
import LinearComponent from "../linearComponent/LinearComponent";
import {DesktopDateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import arrowIcon from "../../assets/icons/right-icon.png";

const getLeagueIdentifier = (league, leagueId) => league + leagueId;

const isMatchSelectedOdds = (homeFirstHalf, awayFirstHalf, homeTotalScore, awayTotalScore, selectedOdds) => {
    switch (selectedOdds) {
        case "iy1-ms1":
            return homeFirstHalf > awayFirstHalf && homeTotalScore > awayTotalScore;
        case "iy1-msx":
            return homeFirstHalf > awayFirstHalf && homeTotalScore === awayTotalScore;
        case "iy1-ms2":
            return homeFirstHalf > awayFirstHalf && homeTotalScore < awayTotalScore;
        case "iyx-ms1":
            return homeFirstHalf === awayFirstHalf && homeTotalScore > awayTotalScore;
        case "iyx-msx":
            return homeFirstHalf === awayFirstHalf && homeTotalScore === awayTotalScore;
        case "iyx-ms2":
            return homeFirstHalf === awayFirstHalf && homeTotalScore < awayTotalScore;
        case "iy2-ms1":
            return homeFirstHalf < awayFirstHalf && homeTotalScore > awayTotalScore;
        case "iy2-msx":
            return homeFirstHalf < awayFirstHalf && homeTotalScore === awayTotalScore;
        case "iy2-ms2":
            return homeFirstHalf < awayFirstHalf && homeTotalScore < awayTotalScore;
        case "0goals":
            return homeTotalScore + awayTotalScore >= 0;
        case "1goals":
            return homeTotalScore + awayTotalScore >= 1;
        case "2goals":
            return homeTotalScore + awayTotalScore >= 2;
        case "3goals":
            return homeTotalScore + awayTotalScore >= 3;
        case "4goals":
            return homeTotalScore + awayTotalScore >= 4;
        case "5goals":
            return homeTotalScore + awayTotalScore >= 5;
        case "6goals":
            return homeTotalScore + awayTotalScore >= 6;
        case "7goals":
            return homeTotalScore + awayTotalScore >= 7;
        default:
            return false;
    }
}

function OddsResults(props) {
    const {favOddsResultsMatches, lang, i18String, isPro} = useContext(AppContext);

    const [liveInstantMatches, setLiveInstantMatches] = useState(null);
    const [fixturesData, setFixturesData] = useState(null);
    const [liveData, setLiveData] = useState(null);
    const [fetchFavMatches, setFetchFavMatches] = useState([]);
    const [live, setLive] = useState(window.setLiveStatus || false);
    const [isShownDates, setIsShownDates] = useState(window.setShownDateStatus || false);
    const [isShownSearch, setIsShownSearch] = useState(window.setShownSearchStatus || false);
    const [statsType, setStatsType] = useState('oddsStats');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOdds, setSelectedOdds] = useState('iy1-ms1');
    const [currentStats, setCurrentStats] = useState('oddsHeader');
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().substr(0, 10));
    const [selectedPercent, setSelectedPercent] = useState(0);
    const [selectedTypeForSelect, setSelectedTypeForSelect] = useState('All');

    //const [statsButtonsWeb, setStatsButtonsWeb] = useState([]);
    //const [statsButtonsMobile, setStatsButtonsMobile] = useState([]);
    const [week, setWeek] = useState([
        {day: dayjs(currentDate).add(-3, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(-2, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(-1, 'days').format('YYYY-MM-DD')},
        {day: new Date().toISOString().substr(0, 10),},
    ]);

    const oddsValue = [
        { name: i18String('HT 1 / FT 1'), id: "iy1-ms1" },
        { name: i18String('HT 1 / FT X'), id: "iy1-msx" },
        { name: i18String('HT 1 / FT 2'), id: "iy1-ms2" },
        { name: i18String('HT X / FT 1'), id: "iyx-ms1" },
        { name: i18String('HT X / FT X'), id: "iyx-msx" },
        { name: i18String('HT X / FT 2'), id: "iyx-ms2" },
        { name: i18String('HT 2 / FT 1'), id: "iy2-ms1" },
        { name: i18String('HT 2 / FT X'), id: "iy2-msx" },
        { name: i18String('HT 2 / FT 2'), id: "iy2-ms2" },
    ];

    const totalGoalsValue = [
        { name: i18String('+0 Goals'), id: "0goals" },
        { name: i18String('+1 Goals'), id: "1goals" },
        { name: i18String('+2 Goals'), id: "2goals" },
        { name: i18String('+3 Goals'), id: "3goals" },
        { name: i18String('+4 Goals'), id: "4goals" },
        { name: i18String('+5 Goals'), id: "5goals" },
        { name: i18String('+6 Goals'), id: "6goals" },
        { name: i18String('+7 Goals'), id: "7goals" },
    ];


    useEffect(() => {
        const handleLiveIcon = () => {
            setLive(window.setLiveStatus);
        };
        const handleDateIcon = () => {
            setIsShownDates(window.setShownDateStatus);
        };
        const handleSearch = () => {
            setIsShownSearch(window.setShownSearchStatus);
        };

        // Toggle değişimini dinle
        window.addEventListener('liveChange', handleLiveIcon);
        window.addEventListener('shownDateStatus', handleDateIcon);
        window.addEventListener('shownSearchStatus', handleSearch);

        // Cleanup için event listener'ı kaldır
        return () => {
            window.removeEventListener('liveChange', handleLiveIcon);
            window.removeEventListener('shownDateStatus', handleDateIcon);
            window.removeEventListener('shownSearchStatus', handleSearch);
        };
    }, []);

    useEffect(() => {
        if (statsType === 'refereeStats') {
            setSelectedPercent(0);
        }

    }, [statsType]);

    /*Stats CODES*/
    useEffect(() => {
        fetchFixtures(currentDate, lang).then((data) => {
            const fixtureData = data.data.data;
            setFixturesData(fixtureData);
            setLiveData(fixtureData);
        })
    }, [currentDate, lang]);

    const fixtureMatches = [];
    const liveLeaguesIndex = {};
    if (fixturesData) {
        fixturesData.forEach((leagues, index) => {
            const {leagueId, league, areaId, area, seasonId} = leagues;
            const lid = getLeagueIdentifier(league, leagueId);
            liveLeaguesIndex[lid] = index;

            const matchesWithAllSelectedVal = leagues.matches.filter(match => {
                if (statsType !== 'refereeStats') {
                    /*const parts = typeof selectedPercent === 'string' ? selectedPercent.split(" - ") : null;
                    const result = parts && parts.length === 2 ? {type: parts[0], value: parts[1]} : null;*/
                    if (match[statsType]) {
                        if (statsType === 'avgStats') {
                            return match[statsType]?.every((item) => {
                                if (item.name.includes(selectedTypeForSelect?.split('_')[0])) {
                                    return item.val >= parseFloat(selectedPercent);
                                }
                                // Returning true if not 'Corners PG' to continue the every function.
                                return true;
                            });
                        } else {
                            if (match[statsType]) {
                                return match[statsType]?.every((item) => Number(item.val.slice(0, -1)) >= Number(selectedPercent));
                            }
                        }
                    } else {
                        if (parseInt(selectedPercent) === 0) {
                            return match
                        }
                    }
                } else {
                    return match
                }
            });
            matchesWithAllSelectedVal.forEach(match => {
                fixtureMatches.push({
                    leagueDetail: {leagueId, league, areaId, area, seasonId},
                    match
                });
            })
        })
    }

    let filteredInstantLiveMatches = [];
    if (liveInstantMatches) {
        filteredInstantLiveMatches = fixtureMatches.map(matches => {
            const findLiveInstant = liveInstantMatches.find(liveMatch => liveMatch.id === matches.match?.id);

            if (["Played", "Playing"].includes(matches.match.status)) {
                const homeFirstHalf = matches.match.hp1 || 0;
                const awayFirstHalf = matches.match.ap1 || 0;
                const homeTotalScore = matches.match.hscore || 0;
                const awayTotalScore = matches.match.ascore || 0;

                if (isMatchSelectedOdds(homeFirstHalf, awayFirstHalf, homeTotalScore, awayTotalScore, selectedOdds)) {
                    return {
                        ...matches,
                        findLiveInstant,
                    };
                }
            }

            return null;
        }).filter(match => match !== null);
    }

    const matchesByLeagues = {};
    const leagueDetails = {};
    filteredInstantLiveMatches.forEach(liveMatch => {
        const {leagueDetail, match, findLiveInstant} = liveMatch;
        const asd = {...match, ...findLiveInstant};
        const lid = getLeagueIdentifier(leagueDetail.league, leagueDetail.leagueId);

        leagueDetails[lid] = leagueDetail;

        const matches = matchesByLeagues[lid] || [];
        matchesByLeagues[lid] = [...matches, asd];
    })

    const sections = Object.entries(matchesByLeagues).map(entry => {
        const [lid, matches] = entry;
        return {
            title: leagueDetails[lid],
            data: matches
        }
    })/*.sort(
        (a, b) => liveLeaguesIndex[a.title.league] - liveLeaguesIndex[b.title.league],
    );*/

    /*FAV MATCHES CODES*/
    useEffect(() => {
        if (favOddsResultsMatches.length > 0) {
            const matchesId = favOddsResultsMatches.map(item => item.match.id);

            fetchFavoritesMatches(matchesId).then(data => {
                const fetchMatches = data.data.data;
                setFetchFavMatches(fetchMatches);
            });

            const interval = setInterval(() => {
                fetchFavoritesMatches(matchesId).then(data => {
                    const fetchMatches = data.data.data;
                    setFetchFavMatches(fetchMatches);
                });
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [favOddsResultsMatches]);

    const favMatchesByLeagues = {};
    const favLeagueDetails = {};

    favOddsResultsMatches.forEach(favMatch => {
        const { leagueDetail, match } = favMatch;
        const lid = getLeagueIdentifier(leagueDetail.league, leagueDetail.leagueId);

        favLeagueDetails[lid] = leagueDetail;

        const fetchFavMatch = fetchFavMatches.find(item => item.id === match.id);
        const matches = favMatchesByLeagues[lid] || [];

        if (["Played", "Playing"].includes(match.status)) {
            const homeFirstHalf = match.hp1 || 0;
            const awayFirstHalf = match.ap1 || 0;
            const homeTotalScore = match.hscore || 0;
            const awayTotalScore = match.ascore || 0;

            switch (selectedOdds) {
                case "iy1-ms1":
                    if (homeFirstHalf > awayFirstHalf && homeTotalScore > awayTotalScore) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "iy1-msx":
                    if (homeFirstHalf > awayFirstHalf && homeTotalScore === awayTotalScore) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "iy1-ms2":
                    if (homeFirstHalf > awayFirstHalf && homeTotalScore < awayTotalScore) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "iyx-ms1":
                    if (homeFirstHalf === awayFirstHalf && homeTotalScore > awayTotalScore) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "iyx-msx":
                    if (homeFirstHalf === awayFirstHalf && homeTotalScore === awayTotalScore) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "iyx-ms2":
                    if (homeFirstHalf === awayFirstHalf && homeTotalScore < awayTotalScore) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "iy2-ms1":
                    if (homeFirstHalf < awayFirstHalf && homeTotalScore > awayTotalScore) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "iy2-msx":
                    if (homeFirstHalf < awayFirstHalf && homeTotalScore === awayTotalScore) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "iy2-ms2":
                    if (homeFirstHalf < awayFirstHalf && homeTotalScore < awayTotalScore) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "0goals":
                    if (homeTotalScore + awayTotalScore >= 0) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "1goals":
                    if (homeTotalScore + awayTotalScore >= 1) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "2goals":
                    if (homeTotalScore + awayTotalScore >= 2) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "3goals":
                    if (homeTotalScore + awayTotalScore >= 3) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "4goals":
                    if (homeTotalScore + awayTotalScore >= 4) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "5goals":
                    if (homeTotalScore + awayTotalScore >= 5) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "6goals":
                    if (homeTotalScore + awayTotalScore >= 6) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                case "7goals":
                    if (homeTotalScore + awayTotalScore >= 7) {
                        return favMatchesByLeagues[lid] = [
                            ...matches,
                            { ...match, ...fetchFavMatch },
                        ];
                    }
                    break;
                default:
                    return null;
            }
        }
        return null;
    });


    const favSections = Object.entries(favMatchesByLeagues).map(entry => {
        const [lid, matches] = entry;
        return {
            title: favLeagueDetails[lid],
            data: matches,
        };
    });

    /*LIVE MATCHES CODES*/
    useEffect(() => {
        const today = new Date().toISOString().substr(0, 10);
        if (currentDate === today) {
            fetchLiteMatches().then(data => {
                const instantMatches = data.data.data;
                setLiveInstantMatches(instantMatches);
            });

            const interval = setInterval(() => {
                fetchLiteMatches().then(data => {
                    const instantMatches = data.data.data;
                    setLiveInstantMatches(instantMatches);
                });
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [currentDate]);

    const liveMatches = [];
    const liveInstantLeaguesIndex = {};
    if (liveData) {
        liveData.forEach((leagues, index) => {
            const {leagueId, league, areaId, area} = leagues;
            const lid = getLeagueIdentifier(league, leagueId);
            liveInstantLeaguesIndex[lid] = index;

            const matchesWithAllSelectedVal = leagues.matches.filter(match => {
                return liveInstantMatches?.some(instantMatch => {

                    if (statsType !== 'refereeStats') {
                        if (match[statsType]) {
                            if (statsType === 'avgStats') {
                                return match.id === instantMatch.id && instantMatch.status === 'Playing' &&
                                    match[statsType]?.every((item) => {
                                        if (item.name.includes(selectedTypeForSelect?.split('_')[0])) {
                                            return item.val >= parseFloat(selectedPercent);
                                        }
                                        // Returning true if not 'Corners PG' to continue the every function.
                                        return true;
                                    });
                            } else {
                                return match.id === instantMatch.id && instantMatch.status === 'Playing' &&
                                    match[statsType]?.every(item => Number(item.val.slice(0, -1)) >= Number(selectedPercent));
                            }
                        } else {
                            if (parseInt(selectedPercent) === 0) {
                                return match.id === instantMatch.id && instantMatch.status === 'Playing'
                            }
                        }
                    } else {
                        return match.id === instantMatch.id && instantMatch.status === 'Playing'
                    }
                });
            });
            matchesWithAllSelectedVal.forEach(match => {
                liveMatches.push({
                    leagueDetail: {leagueId, league, areaId, area},
                    match,
                });
            })
        });
    }

    let filteredLiveMatches = [];
    if (liveInstantMatches) {
        const liveInstant = liveInstantMatches.filter(
            item => item.status === 'Playing',
        );

        filteredLiveMatches = fixtureMatches.map(matches => {
            const findLiveInstant = liveInstant.find(match => match.id === matches.match.id,);

            if (liveInstant && ["Playing"].includes(matches.match.status)) {
                const homeFirstHalf = matches.match.hp1 || 0;
                const awayFirstHalf = matches.match.ap1 || 0;
                const homeTotalScore = matches.match.hscore || 0;
                const awayTotalScore = matches.match.ascore || 0;

                if (isMatchSelectedOdds(homeFirstHalf, awayFirstHalf, homeTotalScore, awayTotalScore, selectedOdds)) {
                    return {
                        ...matches,
                        findLiveInstant,
                    };
                }
            }

            return null;
        }).filter(match => match !== null);
    }

    const liveMatchesByLeagues = {};
    const liveLeagueDetails = {};
    filteredLiveMatches.forEach(liveMatch => {
        const {leagueDetail, match, findLiveInstant} = liveMatch;
        const lid = getLeagueIdentifier(leagueDetail.league, leagueDetail.leagueId);
        const matchAndLiveInstant = {...match, ...findLiveInstant};

        liveLeagueDetails[lid] = leagueDetail;

        const matches = liveMatchesByLeagues[lid] || [];
        liveMatchesByLeagues[lid] = [...matches, matchAndLiveInstant];
    });

    const liveSections = Object.entries(liveMatchesByLeagues).map(entry => {
        const [lid, matches] = entry;
        return {
            title: liveLeagueDetails[lid],
            data: matches,
        };
    })/*.sort(
        (a, b) => liveInstantLeaguesIndex[a.title.league] - liveInstantLeaguesIndex[b.title.league],
    );*/

    const liveMatchButton = (live) => {
        setLive(!live)
    }

    //Week Days Format
    const format = (day) => {
        return dayjs(day).format('ddd')
    }
    const formatDDDForMobile = (day) => {
        return dayjs(day).format('ddd')
    }

    const format2 = (day) => {
        return dayjs(day).format('DD')
    }

    const clickDays = async (day) => {
        await setCurrentDate(day)
    }

    const activeDayStyle = (day) => {
        if (currentDate === day.day) {
            return 'active-week-day'
        }
    }

    const daysFormatFunc = (date) => {
        const currentDate = new Date().toISOString().substr(0, 10);
        if (date.day === currentDate) {
            return (
                <div>
                    <div className='home-page-week-days-container'>
                        <span>{format2(date.day)}</span>
                        <span className='stats-date-day-name'>{i18String('Today')}</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='home-page-week-days-container'>
                    <span>{format2(date.day)}</span>
                    <span className='stats-date-day-name'>{format(date.day)}</span>
                </div>
            )
        }
    }

    const beforeDay = () => {
        const beforeDay = dayjs(currentDate).add(-1, 'days').format('YYYY-MM-DD');
        setCurrentDate(beforeDay);
    }
    const nextDay = () => {
        const beforeDay = dayjs(currentDate).add(1, 'days').format('YYYY-MM-DD');
        setCurrentDate(beforeDay);
    }

    if (!fixturesData) {
        return (
            <div className=" const-width loading-container">
                <div>
                    <div className="loading blink"></div>
                    <div>
                        {i18String('Loading')}
                    </div>
                </div>
            </div>
        )
    }

    const selectOdds = (odds) => {
        setSelectedOdds(odds.id);
    }

    return (
        <DocumentMeta {...homepageMeta[lang]}>
            <div className="const-width fixture-section">
                <div className="fixtures-general-container">
                    {/*FOR WEB*/}
                    <div className="odds-results-fixtures-top-header">
                        <div className='odds-results-live-and-date-header-container'>
                            <div className="live-status-container">
                                <div className="live-container" onClick={() => liveMatchButton(live)}>
                                    {
                                        live ?
                                            <div className="live-match">
                                                <img className="live-icon" src={livePng} alt=""/>
                                                <span className="live-text">{i18String('Live')}</span>
                                                <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                            </div>
                                            :
                                            <div className="not-live">
                                                <img className="not-live-icon" src={livePng} alt=""/>
                                                <span className="not-live-text">{i18String('Live')}</span>
                                                <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                            </div>
                                    }
                                </div>
                            </div>

                            <div className="stats-week-days-section">
                                {
                                    week.map((day) => {
                                        return (
                                            <div key={day.day} className="week-days-container">
                                                <div onClick={() => clickDays(day.day)}
                                                     className={`${'dates'} ${activeDayStyle(day)}`}>
                                                    {/*<span>{format2(day.day)}</span>
                                            <span className='stats-date-day-name'>{format(day.day)}</span>*/}
                                                    {daysFormatFunc(day)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className='live-scores-date-time-picker'>
                                <div onClick={beforeDay} className='date-arrow-container reverse-right-arrow-icon'>
                                    <img className='arrow-img' src={arrowIcon} alt="Before"/>
                                </div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDateTimePicker
                                        sx={{
                                            width: '130px',
                                        }}
                                        views={["year", "month", "day"]}
                                        format="DD-MM-ddd"
                                        onChange={(value) => clickDays(dayjs(value).format('YYYY-MM-DD'))}
                                        value={dayjs(currentDate)}
                                        defaultValue={dayjs(currentDate)}/>
                                </LocalizationProvider>
                                <div onClick={nextDay} className='date-arrow-container'>
                                    <img className='arrow-img' src={arrowIcon} alt="Next"/>
                                </div>
                            </div>
                        </div>

                        {/*ODDS BUTTONS*/}
                        <div style={{ flex: 2, alignItems: 'center' }} className='odds-general-section'>
                            <div style={{flex: 1}}>
                                <h6>{i18String('First Half / Match Result')} | </h6>
                            </div>
                            <div className='oddsValueContainer'>
                                {
                                    oddsValue.map((odds, index) => {
                                        const isSelected = selectedOdds === odds.id;
                                        return (
                                            <button
                                                onClick={() => selectOdds(odds)}
                                                key={index}
                                                className={`oddsButton ${isSelected ? 'selectedButton' : ''}`}
                                            >
                                                {odds.name}
                                            </button>
                                        );
                                    })
                                }
                            </div>
                        </div>

                        {/*TOTAL GOALS*/}
                        <div style={{ flex: 2, alignItems: 'center' }} className='odds-general-section'>
                            <div style={{flex: 1}}>
                                <h6>{i18String('Total Goals')} | </h6>
                            </div>
                            <div className='oddsValueContainer'>
                                {
                                    totalGoalsValue.map((odds, index) => {
                                        const isSelected = selectedOdds === odds.id;
                                        return (
                                            <button
                                                onClick={() => selectOdds(odds)}
                                                key={index}
                                                className={`oddsButton ${isSelected ? 'selectedButton' : ''}`}
                                            >
                                                {odds.name}
                                            </button>
                                        );
                                    })
                                }
                            </div>
                        </div>

                    </div>

                    {/*FOR MOBILE*/}
                    <div className="stats-top-header-mobile const-width-content">
                        <LinearComponent />

                        <div className={'live-and-weekdays-container-mobile'}>
                            <div className="live-container" onClick={() => liveMatchButton(live)}>
                                {
                                    live ?
                                        <div className="live-match">
                                            <img className="live-icon" src={livePng} alt=""/>
                                            <span className="live-text">{i18String('Live')}</span>
                                            <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                        </div>
                                        :
                                        <div className="not-live">
                                            <img className="not-live-icon" src={livePng} alt=""/>
                                            <span className="not-live-text">{i18String('Live')}</span>
                                            <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                        </div>
                                }
                            </div>
                            <div className="week-days-section">
                                {
                                    week.map((day) => {
                                        return (
                                            <div key={day.day} className="week-days-container">
                                                <div onClick={() => clickDays(day.day)}
                                                     className={`${'dates'} ${activeDayStyle(day)}`}>
                                                    <span>{format2(day.day)}</span>
                                                    <span>{formatDDDForMobile(day.day)}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        {/*<div className='live-scores-date-time-picker'>
                            <div onClick={beforeDay} className='date-arrow-container reverse-right-arrow-icon'>
                                <img className='arrow-img' src={arrowIcon} alt="Before"/>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDateTimePicker
                                    sx={{
                                        width: '130px',
                                    }}
                                    views={["year", "month", "day"]}
                                    format="DD-MM-ddd"
                                    onChange={(value) => clickDays(dayjs(value).format('YYYY-MM-DD'))}
                                    value={dayjs(currentDate)}
                                    defaultValue={dayjs(currentDate)}/>
                            </LocalizationProvider>
                            <div onClick={nextDay} className='date-arrow-container'>
                                <img className='arrow-img' src={arrowIcon} alt="Next"/>
                            </div>
                        </div>*/}

                        <div className='odds-dropdown-section'>
                            <label htmlFor="dropdown" className='select-odds-filter-label'>{i18String('Select a filter:')}</label>
                            <select id="dropdown" value={selectedOdds} onChange={(event) => setSelectedOdds(event.target.value)}>
                                <optgroup label={i18String('Odds')}>
                                    {oddsValue.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </optgroup>
                                <optgroup label={i18String('Total Goals')}>
                                    {totalGoalsValue.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </optgroup>
                            </select>
                        </div>

                    </div>
                    {/*FAV SECTIONS*/}
                    {
                        favSections && favOddsResultsMatches.length > 0 && favSections.map((league) => {
                            const lid = getLeagueIdentifier(league.title.league, league.title.leagueId);
                            const favMatches = favMatchesByLeagues[lid] || [];
                            const searchResultFavMatches = favMatches.filter(
                                (match) =>
                                    match.home.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    match.away.toLowerCase().includes(searchTerm.toLowerCase())
                            );
                            const matchesWithAllSelectedVal = searchResultFavMatches.filter(match => {
                                if (statsType !== 'refereeStats') {
                                    if (match[statsType]) {
                                        if (statsType === 'avgStats') {
                                            return match[statsType]?.every((item) => {
                                                if (item.name.includes(selectedTypeForSelect?.split('_')[0])) {
                                                    return item.val >= parseFloat(selectedPercent);
                                                }
                                                // Returning true if not 'Corners PG' to continue the every function.
                                                return true;
                                            });
                                        } else {
                                            if (match[statsType]) {
                                                return match[statsType]?.every((item) => Number(item.val.slice(0, -1)) >= Number(selectedPercent));
                                            }
                                        }
                                    } else {
                                        if (parseInt(selectedPercent) === 0) {
                                            return match
                                        }
                                    }
                                } else {
                                    return match
                                }
                            });

                            return (
                                favOddsResultsMatches.length === 0 ? null :
                                    <LeagueAndMatchesTable key={`${league.title.league}-${league.title.leagueId}`}
                                                           favMatches={matchesWithAllSelectedVal}
                                                           statsType={statsType}
                                                           currentStats={currentStats} league={league}
                                                           matches={matchesWithAllSelectedVal}
                                                           selectedTypeForSelect={selectedTypeForSelect}
                                                           searchTerm={searchTerm}
                                    />
                            )
                        })
                    }
                    {/*STATS SECTIONS*/}
                    {
                        sections && !live && sections.map((league, index) => {
                            const lid = getLeagueIdentifier(league.title.league, league.title.leagueId);
                            const matches = matchesByLeagues[lid] || [];
                            const searchResultMatches = matches
                                .filter(
                                    (match) =>
                                        match.home.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        match.away.toLowerCase().includes(searchTerm.toLowerCase())
                                );
                            return (
                                searchResultMatches.length === 0 ? null :
                                    <LeagueAndMatchesTable key={`${league.title.league}-${league.title.leagueId}`}
                                                           statsType={statsType}
                                                           index={index}
                                                           currentStats={currentStats}
                                                           live={live}
                                                           league={league}
                                                           matches={searchResultMatches}
                                                           selectedTypeForSelect={selectedTypeForSelect}
                                                           searchTerm={searchTerm}
                                    />
                            )
                        })
                    }
                    {/*LIVE SECTIONS*/}
                    {
                        liveSections.length !== 0 && live && liveSections.map((league, index) => {
                            const lid = getLeagueIdentifier(league.title.league, league.title.leagueId);
                            const liveMatches = liveMatchesByLeagues[lid] || [];
                            const searchResultLiveMatches = liveMatches.filter(
                                (match) =>
                                    match.home.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    match.away.toLowerCase().includes(searchTerm.toLowerCase())
                            );
                            return (
                                <div>
                                    {
                                        searchResultLiveMatches.length === 0 ? null :
                                            <LeagueAndMatchesTable key={`${league.title.league}-${league.title.leagueId}`}
                                                                   statsType={statsType}
                                                                   currentStats={currentStats}
                                                                   league={league}
                                                                   index={index}
                                                                   live={live}
                                                                   matches={searchResultLiveMatches}
                                                                   selectedTypeForSelect={selectedTypeForSelect}
                                                                   searchTerm={searchTerm}
                                            />
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        liveSections?.length === 0 && live &&
                        <div className={'league-name-container no-live-matches-container'}>
                            {
                                lang === 'tr' ?
                                    <span className={'no-live-matches-text'}>İstediğiniz filtreye uygun veya canlı maç yok!</span>
                                    :
                                    <span className={'no-live-matches-text'}>There are no live matches or match the filter you want!</span>
                            }
                        </div>
                    }
                    {
                        sections?.length === 0 &&
                        <div className={'league-name-container no-live-matches-container'}>
                            {
                                lang === 'tr' ?
                                    <span
                                        className={'no-live-matches-text'}>İstediğiniz filtreye uygun eşleşme yok!</span>
                                    :
                                    <span className={'no-live-matches-text'}>There are no matches suitable for the filter you want!</span>
                            }
                        </div>
                    }
                </div>
            </div>
        </DocumentMeta>
    );
}

export default OddsResults;
