import './App.css';
import Header from "./components/header/Header";
import Application from "./components/Application";
import {AppProvider} from "./context/AppContext";
import {BrowserRouter} from "react-router-dom";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <AppProvider>
                <Application />
            </AppProvider>
        </BrowserRouter>
    </div>
  );
}

export default App;
