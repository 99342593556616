import React, {useContext} from 'react';
import Out from '../../../assets/icons/out.png'
import In from '../../../assets/icons/in.png'
import Ball from '../../../assets/icons/ball.png'
import noTeamLogo from "../../../assets/icons/noTeamLogo.png";
import yCard from "../../../assets/icons/mackolik-ycard.gif";
import rCard from "../../../assets/icons/mackolik-rcard.gif";
import penGoal from "../../../assets/icons/penalty_goal.gif";
import ownGoal from "../../../assets/icons/own-goal.gif";
import {AppContext} from "../../../context/AppContext";

function MatchInfo(props) {
    const {i18String} = useContext(AppContext);
    const {matchTeamsDetail, timeLineData, matchDetailData} = props;

    const onImageError = (e) => {
        e.target.src = noTeamLogo;
    }

    return (
        <div>
            {/*FOR WEB*/}
            <div className='timeline-web-container'>
                {
                    timeLineData?.length !== 0 && matchTeamsDetail &&
                    <div className='time-line-and-teams-section'>
                        <div className='timeline-teams-container'>
                            <div className='match-detail-home-team-events-container'>
                                <a className='match-info-title-team-name' target={"_blank"} href={`/team-detail/${matchTeamsDetail.homeId}`}>{matchTeamsDetail.home}</a>
                                <img className="match-detail-timeline-teams-logo"
                                     src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.homeId}.png`}
                                     onError={(e) => onImageError(e)}
                                     alt={matchTeamsDetail.home}/>
                            </div>

                            <div className='event-time-container'>

                            </div>

                            <div className='match-detail-away-team-events-container'>
                                <img className="match-detail-timeline-teams-logo match-detail-timeline-teams-logo-away"
                                     src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.awayId}.png`}
                                     onError={(e) => onImageError(e)}
                                     alt={matchTeamsDetail.away}/>

                                <a className='match-info-title-team-name' target={"_blank"} href={`/team-detail/${matchTeamsDetail.awayId}`}>{matchTeamsDetail.away}</a>
                            </div>
                        </div>
                    </div>
                }

                <div>
                    {
                        timeLineData &&
                        timeLineData.map((event, index) => {
                            return (
                                <div>
                                    {/*YELLOW CARD*/}
                                    {
                                        event.type === 'YellowCard' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <span className='home-event-player-name'>{event.playerName}</span>
                                                        <img src={yCard} alt=""/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img src={yCard} alt=""/>
                                                        <span className='away-event-player-name'>{event.playerName}</span>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*RED CARD*/}
                                    {
                                        event.type === 'RedCard' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <span className='home-event-player-name'>{event.playerName}</span>
                                                        <img src={rCard} alt=""/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img src={rCard} alt=""/>
                                                        <span className='away-event-player-name'>{event.playerName}</span>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*VAR*/}
                                    {
                                        event.type === 'V_A_R' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <span className='home-event-player-name'>{event.playerName} V</span>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <span className='away-event-player-name'> V {event.playerName}</span>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*SUBSTITUTION*/}
                                    {
                                        event.type === 'Substitution' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <span className='away-event-player-name'>{event.playerOutName}</span>
                                                        <img className='timeline-icons' src={Out} alt="Out"/>

                                                        <span className='away-event-player-name'>{event.playerInName}</span>
                                                        <img className='timeline-icons' src={In} alt="In"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <div className='away-events-container'>
                                                            <img className='timeline-icons' src={In} alt="In"/>
                                                            <span className='home-event-player-name'>{event.playerInName}</span>

                                                            <img className='timeline-icons' src={Out} alt="Out"/>
                                                            <span className='home-event-player-name'>{event.playerOutName}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*SUBSTITUTION INJURED*/}
                                    {
                                        event.type === 'Substitution_Injured' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <span className='away-event-player-name'>{event.playerOutName}</span>
                                                        <img className='timeline-icons' src={Out} alt="Out"/>

                                                        <span className='away-event-player-name'>{event.playerInName}</span>
                                                        <img className='timeline-icons' src={In} alt="In"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <div className='away-events-container'>
                                                            <img className='timeline-icons' src={In} alt="In"/>
                                                            <span className='home-event-player-name'>{event.playerInName}</span>

                                                            <img className='timeline-icons' src={Out} alt="Out"/>
                                                            <span className='home-event-player-name'>{event.playerOutName}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*GOAL*/}
                                    {
                                        event.type === 'Goal' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        {
                                                            event.playerAssistName &&
                                                            <a className='home-assist-player-name'>({event.playerAssistName})</a>
                                                        }

                                                        <a className='home-event-goal-player-name'>{event.playerName}</a>
                                                        <img className='timeline-icons' src={Ball} alt="Goal"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img className='timeline-icons' src={Ball} alt="Goal"/>
                                                        <a className='away-event-goal-player-name'>{event.playerName}</a>

                                                        {
                                                            event.playerAssistName &&
                                                            <a className='away-assist-player-name'>({event.playerAssistName})</a>
                                                        }
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*GOAL PENALTY*/}
                                    {
                                        event.type === 'Goal_Penalty' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        {
                                                            event.playerAssistName &&
                                                            <a className='home-assist-player-name'>({event.playerAssistName})</a>
                                                        }

                                                        <a className='home-event-goal-player-name'>{event.playerName}</a>
                                                        <img className='pen-goal-icon' src={penGoal} alt="Goal Penalty"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img className='pen-goal-icon' src={penGoal} alt="Goal Penalty"/>
                                                        <a className='away-event-goal-player-name'>{event.playerName}</a>

                                                        {
                                                            event.playerAssistName &&
                                                            <a className='away-assist-player-name'>({event.playerAssistName})</a>
                                                        }
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*GOAL OWN*/}
                                    {
                                        event.type === 'Goal_Own' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        {
                                                            event.playerAssistName &&
                                                            <a className='home-assist-player-name'>({event.playerAssistName})</a>
                                                        }

                                                        <a className='home-event-goal-player-name'>{event.playerName}</a>
                                                        <img className='timeline-icons' src={ownGoal} alt="Own Goal"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img className='timeline-icons' src={ownGoal} alt="Own Goal"/>
                                                        <a className='away-event-goal-player-name'>{event.playerName}</a>

                                                        {
                                                            event.playerAssistName &&
                                                            <a className='away-assist-player-name'>({event.playerAssistName})</a>
                                                        }
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>

                {
                    matchDetailData?.ref &&
                    matchDetailData.ref.apps &&
                    matchDetailData.ref.redsAvg &&
                    matchDetailData.ref.yellowsAvg &&
                    matchDetailData.ref.pens &&
                    <div>
                        <div style={{background: '#e6eaeb', padding: '5px 0', textAlign: 'center'}}>
                            <span style={{fontSize: '13px', color: 'var(--H3Text1)', fontWeight: '600'}}>{i18String('Referee Season info')}</span>
                        </div>
                        <div className='time-line-and-teams-section'>
                            {
                                matchTeamsDetail &&
                                <div className='timeline-teams-container'>
                                    <div className='match-detail-home-team-events-container'>
                                        <a className='match-info-title-team-name' target={"_blank"} href={`/team-detail/${matchTeamsDetail.homeId}`}>{matchTeamsDetail.home}</a>
                                        <img className="match-detail-timeline-teams-logo"
                                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.homeId}.png`}
                                             onError={(e) => onImageError(e)}
                                             alt={matchTeamsDetail.home}/>
                                    </div>

                                    <div className='event-time-container'>

                                    </div>

                                    <div className='match-detail-away-team-events-container'>
                                        <img className="match-detail-timeline-teams-logo match-detail-timeline-teams-logo-away"
                                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.awayId}.png`}
                                             onError={(e) => onImageError(e)}
                                             alt={matchTeamsDetail.away}/>

                                        <a className='match-info-title-team-name' target={"_blank"} href={`/team-detail/${matchTeamsDetail.awayId}`}>{matchTeamsDetail.away}</a>
                                    </div>
                                </div>
                            }
                        </div>

                        <div style={{padding: '5px'}}>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('Referee')}</span>
                                <div style={{display: 'flex'}}>
                                    <span className='match-detail-area-text'> {matchDetailData.ref.name}, </span>
                                    <a className='match-detail-area-text'> {matchDetailData.ref.country}</a>
                                    <img className="match-detail-area-logo" style={{marginLeft: '10px'}}
                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${matchDetailData.ref.countryId}.png`}
                                         alt=""/>
                                </div>
                            </div>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('P-Apps')}</span>
                                <span className='referee-info-text'> {matchDetailData.ref.apps}</span>
                            </div>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('Red Cards PG')}</span>
                                <span className='referee-info-text'> {matchDetailData.ref.redsAvg}</span>
                            </div>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('Yellow Cards PG')}</span>
                                <span className='referee-info-text'> {matchDetailData.ref.yellowsAvg}</span>
                            </div>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('Pens')}</span>
                                <span className='referee-info-text'> {matchDetailData.ref.pens}</span>
                            </div>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('Pens PG')}</span>
                                <span className='referee-info-text'> {matchDetailData.ref.pensAvg}</span>
                            </div>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('Fouls PG')}</span>
                                <span className='referee-info-text'> {matchDetailData.ref.foulsAvg}</span>
                            </div>
                        </div>
                    </div>
                }
            </div>

            {/*FOR MOBILE*/}
            <div className='timeline-mobile-container'>
                {
                    timeLineData?.length !== 0 &&
                    <div className='time-line-and-teams-section'>
                        <span className='mobile-timeline-title'>{i18String('Timeline')}</span>
                    </div>
                }

                <div className='timeline-mobile-content-container'>
                    {
                        timeLineData &&
                        timeLineData.map((event, index) => {
                            return (
                                <div>
                                    {/*YELLOW CARD*/}
                                    {
                                        event.type === 'YellowCard' &&
                                        <div className='timeline-event-container'>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>

                                                        <span className='home-event-player-name'>{event.playerName}</span>
                                                        <img src={yCard} alt=""/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img src={yCard} alt=""/>
                                                        <span className='away-event-player-name'>{event.playerName}</span>

                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*RED CARD*/}
                                    {
                                        event.type === 'RedCard' &&
                                        <div className='timeline-event-container'>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>

                                                        <span className='home-event-player-name'>{event.playerName}</span>
                                                        <img src={rCard} alt=""/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img src={rCard} alt=""/>
                                                        <span className='away-event-player-name'>{event.playerName}</span>

                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*VAR*/}
                                    {
                                        event.type === 'V_A_R' &&
                                        <div className='timeline-event-container'>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>

                                                        <span className='home-event-player-name'>{event.playerName} V</span>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <span className='away-event-player-name'> V {event.playerName}</span>

                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*SUBSTITUTION*/}
                                    {
                                        event.type === 'Substitution' &&
                                        <div className='timeline-event-container'>
                                            {
                                                event.isHome &&
                                                    <div className='home-events-container'>
                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>

                                                        <span className='away-event-player-name'>{event.playerOutName}</span>
                                                        <img className='timeline-icons' src={Out} alt="Out"/>

                                                        <span className='away-event-player-name'>{event.playerInName}</span>
                                                        <img className='timeline-icons' src={In} alt="In"/>
                                                    </div>
                                            }

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <div className='away-events-container'>
                                                            <img className='timeline-icons' src={In} alt="In"/>
                                                            <span className='home-event-player-name'>{event.playerInName}</span>

                                                            <img className='timeline-icons' src={Out} alt="Out"/>
                                                            <span className='home-event-player-name'>{event.playerOutName}</span>
                                                        </div>

                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*SUBSTITUTION INJURED*/}
                                    {
                                        event.type === 'Substitution_Injured' &&
                                        <div className='timeline-event-container'>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>

                                                        <span className='away-event-player-name'>{event.playerOutName}</span>
                                                        <img className='timeline-icons' src={Out} alt="Out"/>

                                                        <span className='away-event-player-name'>{event.playerInName}</span>
                                                        <img className='timeline-icons' src={In} alt="In"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <div className='away-events-container'>
                                                            <img className='timeline-icons' src={In} alt="In"/>
                                                            <span className='home-event-player-name'>{event.playerInName}</span>

                                                            <img className='timeline-icons' src={Out} alt="Out"/>
                                                            <span className='home-event-player-name'>{event.playerOutName}</span>
                                                        </div>

                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*GOAL*/}
                                    {
                                        event.type === 'Goal' &&
                                        <div className='timeline-event-container'>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>

                                                        {
                                                            event.playerAssistName &&
                                                            <a className='home-assist-player-name'>({event.playerAssistName})</a>
                                                        }

                                                        <a className='home-event-goal-player-name'>{event.playerName}</a>
                                                        <img className='timeline-icons' src={Ball} alt="Goal"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img className='timeline-icons' src={Ball} alt="Goal"/>
                                                        <a className='away-event-goal-player-name'>{event.playerName}</a>

                                                        {
                                                            event.playerAssistName &&
                                                            <a className='away-assist-player-name'>({event.playerAssistName})</a>
                                                        }

                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*GOAL PENALTY*/}
                                    {
                                        event.type === 'Goal_Penalty' &&
                                        <div className='timeline-event-container'>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>

                                                        {
                                                            event.playerAssistName &&
                                                            <a className='home-assist-player-name'>({event.playerAssistName})</a>
                                                        }

                                                        <a className='home-event-goal-player-name'>{event.playerName}</a>
                                                        <img className='pen-goal-icon' src={penGoal} alt="Goal Penalty"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img className='pen-goal-icon' src={penGoal} alt="Goal Penalty"/>
                                                        <a className='away-event-goal-player-name'>{event.playerName}</a>

                                                        {
                                                            event.playerAssistName &&
                                                            <a className='away-assist-player-name'>({event.playerAssistName})</a>
                                                        }

                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*GOAL OWN*/}
                                    {
                                        event.type === 'Goal_Own' &&
                                        <div className='timeline-event-container'>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>

                                                        {
                                                            event.playerAssistName &&
                                                            <a className='home-assist-player-name'>({event.playerAssistName})</a>
                                                        }

                                                        <a className='home-event-goal-player-name'>{event.playerName}</a>
                                                        <img className='timeline-icons' src={ownGoal} alt="Own Goal"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img className='timeline-icons' src={ownGoal} alt="Own Goal"/>
                                                        <a className='away-event-goal-player-name'>{event.playerName}</a>

                                                        {
                                                            event.playerAssistName &&
                                                            <a className='away-assist-player-name'>({event.playerAssistName})</a>
                                                        }

                                                        <div className='event-time-container'>
                                                            <span className='match-detail-event-minute'>{event.minute}'</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>

                {
                    matchDetailData?.ref &&
                    <div style={{marginTop: '10px'}}>
                        <div style={{background: '#e6eaeb', padding: '5px 0 5px 10px', borderRadius: '10px  10px 0 0'}}>
                            <span style={{fontSize: '12px', color: 'var(--H3Text1)', fontWeight: '600'}}>{i18String('Referee Season info')}</span>
                        </div>
                        <div className='time-line-and-teams-section' style={{padding: '0'}}>
                            {
                                matchTeamsDetail &&
                                <div className='timeline-teams-container'>
                                    <div className='match-detail-home-team-events-container'>
                                        <a className='match-info-title-team-name' target={"_blank"} href={`/team-detail/${matchTeamsDetail.homeId}`}>{matchTeamsDetail.home}</a>
                                        <img className="match-detail-timeline-teams-logo"
                                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.homeId}.png`}
                                             onError={(e) => onImageError(e)}
                                             alt={matchTeamsDetail.home}/>
                                    </div>

                                    <div className='event-time-container'>

                                    </div>

                                    <div className='match-detail-away-team-events-container'>
                                        <img className="match-detail-timeline-teams-logo match-detail-timeline-teams-logo-away"
                                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.awayId}.png`}
                                             onError={(e) => onImageError(e)}
                                             alt={matchTeamsDetail.away}/>

                                        <a className='match-info-title-team-name' target={"_blank"} href={`/team-detail/${matchTeamsDetail.awayId}`}>{matchTeamsDetail.away}</a>
                                    </div>
                                </div>
                            }
                        </div>

                        <div style={{padding: '5px', background: 'var(--H3Light0)', borderRadius: '0  0 10px 10px'}}>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('Referee')}</span>
                                <div style={{display: 'flex'}}>
                                    <span className='match-detail-area-text'> {matchDetailData.ref.name}, </span>
                                    <a className='match-detail-area-text'> {matchDetailData.ref.country}</a>
                                    <img className="match-detail-area-logo" style={{marginLeft: '10px'}}
                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${matchDetailData.ref.countryId}.png`}
                                         alt=""/>
                                </div>
                            </div>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('P-Apps')}</span>
                                <span className='referee-info-text'> {matchDetailData.ref.apps}</span>
                            </div>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('Red Cards PG')}</span>
                                <span className='referee-info-text'> {matchDetailData.ref.redsAvg}</span>
                            </div>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('Yellow Cards PG')}</span>
                                <span className='referee-info-text'> {matchDetailData.ref.yellowsAvg}</span>
                            </div>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('Pens')}</span>
                                <span className='referee-info-text'> {matchDetailData.ref.pens}</span>
                            </div>
                            <div className='refere-info-row-container'>
                                <span className='referee-info-text'>{i18String('Pens PG')}</span>
                                <span className='referee-info-text'> {matchDetailData.ref.pensAvg}</span>
                            </div>
                            <div className='refere-info-row-container' style={{borderBottom: 'none'}}>
                                <span className='referee-info-text'>{i18String('Fouls PG')}</span>
                                <span className='referee-info-text'> {matchDetailData.ref.foulsAvg}</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default MatchInfo;
