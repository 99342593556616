import React, {useContext} from 'react';
import padLock from "../../../assets/icons/padlock.png";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../context/AppContext";

function OddsStatsRow(props) {
    const {i18String} = useContext(AppContext);

    const {value, oddsName, statsType} = props;
    const navigate = useNavigate();

    const toJoinPremium = () => {
        navigate('/join-premium')
    }

    return (
        <td className='stat-style-width-odds'>
            {/*FOR MOBILE*/}
            <div className='stats-container-for-mobile'>
                <td className='stats-container-for-mobile'>
                    <div style={{textAlign: 'center'}}>
                        <span className='stats-type-mobile'>{i18String(oddsName)}</span>
                        <span className='stats-type-for-web'>{value}</span>
                    </div>
                </td>
            </div>
            {/*FOR WEB*/}
            <div className='stats-container-for-web'>
                <div>
                    <span className='stats-type-for-web'>{value}</span>
                </div>
            </div>
        </td>
    );
}

export default OddsStatsRow;
